import { CommonModule, NgClass } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NgImageSliderModule } from 'ng-image-slider-v16';
import { environment } from 'environments/environment';
import { SliderUrlPipe } from '@shared/pipes/slider-url.pipe';
import { PathModifierPipe } from '@shared/pipes/path-modifier.pipe';
import { IResponse } from '@shared/interfaces/iresponse';
import { map } from 'rxjs';
interface IImageSize {
  height: string;
  width: string;
}
interface IImage {
  image?: string,
  thumbImage?: string,
  alt?: string,
  title?: string
}
@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  standalone: true,
  imports: [NgClass,NgImageSliderModule],
  providers: [PathModifierPipe, SliderUrlPipe]
})
export class SliderComponent implements OnChanges {
  @Input() type: 'left' | 'top' = "top";
  @Input() actions: boolean = true;
  imageSize: IImageSize = { height: '140px', width: '25%' }
  Advertisements: Array<IImage> = [];
  constructor(private http: HttpClient, private pathModifier: PathModifierPipe, private sliderUrlPipe: SliderUrlPipe) {}

  ngOnChanges(changes: SimpleChanges): void {
    const { type } = changes;
    if (type.currentValue == 'top') {
      this.imageSize.width = '25%';
      this.getTopSliders();
    } else {
      this.imageSize.width = '98%';
      this.getLeftSlider()
    }
  }

  getTopSliders() {
    this.getSliderForType(false, true).subscribe((data: Array<any>) => {
      if (data?.length) {
        for (let i = 0; i < data.length; i++) {
          this.Advertisements = [...this.Advertisements, { image: <string>this.sliderUrlPipe.transform(data[i]?.SiteURL), thumbImage: this.pathModifier.transform(data[i]?.ImageURL) }]
        }
      }
    })
  }

  getLeftSlider() {
    this.getSliderForType(true, false).subscribe((data: Array<any>) => {
      if (data.length) {
        for (let i = 0; i < data.length; i++) {
          this.Advertisements = [...this.Advertisements, { image: <string>this.sliderUrlPipe.transform(data[i]?.SiteURL), thumbImage: this.pathModifier.transform(data[i]?.ImageURL) }]
        }
      }
    })
  }

  private getSliderForType(leftMenu: boolean, topMenu: boolean) {
    return this.http.get(`${environment.ctUrl}SliderSettings/FetchSliderSettingsForType?IsLeftMenu=${leftMenu}&IsTopMenu=${topMenu}`).pipe(map((res: IResponse) => res.Data))
  }

  openAdd(event: any) {
    window.open(this.Advertisements[event].image)
  }

}
